// LOGIN
export const URL_LOGIN = 'auth/login';
export const URL_LOGOUT = 'auth/logout';
export const URL_GET_USER = 'auth';
export const URL_REFRESH_TOKEN = 'auth/refresh';

export const URL_GENERATE_TOKEN = 'share-token/generate';

export const URL_CONFIRM_EMAIL = 'email-confirmation/confirm';

// REGISTRATION
export const URL_REGISTRATION = 'auth/register';
export const URL_SET_PASSWORD = 'account/set-password';
export const URL_CHANGE_PASSWORD = 'account/change-password';
export const URL_FORGOT_PASSWORD = 'account/restore-password';
export const URL_CHECK_CODE = 'check_code';

//ADMIN
export const URL_ADMIN_GET_SUBMISSIONS = 'submissions';
export const URL_ADMIN_GET_SUBMISSIONS_USERS = `${URL_ADMIN_GET_SUBMISSIONS}/user-registration`;
export const URL_ADMIN_REJECT_USER_REGISTRATION = `${URL_ADMIN_GET_SUBMISSIONS}/review/user-registration/:id/decline`;
export const URL_ADMIN_APPROVE_USER_REGISTRATION = `${URL_ADMIN_GET_SUBMISSIONS}/review/user-registration/:id/approve`;
export const URL_ADMIN_GET_COMPANY_INFO = `${URL_ADMIN_GET_SUBMISSIONS_USERS}/:id`;

// CERTIFICATES
export const URL_GET_CERTIFICATES = 'URL_GET_CERTIFICATES';
export const URL_GET_RETIREMENTS = 'URL_GET_RETIREMENTS';

// TRANSFER
export const URL_TRANSFER = 'URL_TRANSFER';

// USERS
export const URL_GET_USERS = 'URL_GET_USERS';
