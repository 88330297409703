import React from 'react';
import { Role } from '../../../structure/models/user/user';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/reducers/user/userReducer';

type Props = {
  roles: Array<Role | undefined>;
  redirect: string;
};

export const guard =
  <TProps extends Record<string, any>>(
    Component: React.FC<TProps>,
  ): React.FC<Props & TProps> =>
  ({ roles, redirect, ...props }) => {
    const user = useAppSelector(selectUser);

    return roles.includes(user?.role) ? (
      <Component {...(props as unknown as TProps)} />
    ) : (
      <Navigate to={redirect} />
    );
  };
