import React from 'react';
import { BaseButton } from '../Buttons/BaseButton/BaseButton';
import './ContactUsStyle.scss';

export const ContactUs: React.FC = React.memo(() => {
  return (
    <BaseButton className="contact-us">
      <span className="material-symbols-outlined contact-us__icon">
        contact_support
      </span>
      Contact Us
    </BaseButton>
  );
});
