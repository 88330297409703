import {
  AllEntries,
  validationFactory,
} from '../../core/helpers/validationFactory';
import { CompanyType } from '../../../structure/models/company/company';

const { createValidate, createValidator } = validationFactory();

const phoneRegexp = /^(\+\d{2,4})(\d{4,8})$/;

const validators = {
  company_name: createValidator(
    (value: string) => value.length >= 3,
    'Name is short',
  ),
  register_role: createValidator(
    (value: string) => !!value,
    'Type is required',
  ),
  address: createValidator(
    (value: string) => !!value && value.length >= 3,
    'Address is required',
  ),
  address_number: createValidator(
    (value: string) => !!value,
    'Address Number is required',
  ),
  ZIP: createValidator(
    (value: string) => value.length > 4,
    'ZIP should be more than 4 characters',
  ),
  city: createValidator((value: string) => !!value, 'City is required'),
  country: createValidator((value: string) => !!value, 'City is required'),
  rsb: createValidator((value: string, allEntries: AllEntries) => {
    const [, type] = allEntries.find(([key]) => key === 'register_role') || [];

    return type !== CompanyType.Supplier || (!!value && value.length >= 10);
  }, 'PO Number should be more than 10 characters'),
  start_date: createValidator((value: string, allEntries: AllEntries) => {
    const [, type] = allEntries.find(([key]) => key === 'type') || [];

    return type !== CompanyType.Supplier || !!value;
  }, 'Start date is required'),
  end_date: createValidator((value: string, allEntries: AllEntries) => {
    const [, type] = allEntries.find(([key]) => key === 'type') || [];

    return type !== CompanyType.Supplier || !!value;
  }, 'End date is required'),
  primary_name: createValidator((value: string) => !!value, 'Name is required'),
  primary_surname: createValidator(
    (value: string) => !!value,
    'Surname is required',
  ),
  primary_email: createValidator(
    (value: string) => !!value,
    'Email is required',
  ),
  primary_phone: createValidator(
    (value: string) => phoneRegexp.test(value),
    'Phone must have a valid format',
  ),
  primary_position: createValidator(
    (value: string) => !!value,
    'Position is required',
  ),
  secondary_name: createValidator(
    (value: string) => !!value,
    'Name is required',
  ),
  secondary_surname: createValidator(
    (value: string) => !!value,
    'Surname is required',
  ),
  secondary_email: createValidator((value: string, allEntries: AllEntries) => {
    const [, primaryEmail] =
      allEntries.find(
        ([key, email]) => key === 'primary_email' && email === value,
      ) || [];

    return !primaryEmail && !!value;
  }, 'Email is required and it should not be the same as primary email'),
  secondary_phone: createValidator(
    (value: string) => phoneRegexp.test(value),
    'Phone must have a valid format',
  ),
  secondary_position: createValidator(
    (value: string) => !!value,
    'Position is required',
  ),
  agreement: createValidator(
    (value: string) => !!value,
    'Agreement is required',
  ),
};

export const validate = createValidate(validators);
