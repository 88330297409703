import {
  Certificate,
  CertificateOption,
} from '../../../structure/models/certificates/certificates';

export function makeCertificateOptionFromCertificate(
  certificate: Certificate,
): CertificateOption {
  return {
    value: certificate.id,
    label: certificate.bcuid,
  };
}

export function makeCertificatesOptionFromCertificates(
  certificates: Certificate[] | undefined,
): CertificateOption[] {
  if (!certificates) {
    return [];
  }
  return certificates.map(makeCertificateOptionFromCertificate);
}
