import React, { useEffect, useRef, useState } from 'react';
import './TransferStyle.scss';
import { UserBalance } from '../components/UserBalance/UserBalance';
import { Col, Row } from 'react-bootstrap';
import { CustomInput } from '../components/Form/Input';
import {
  colourStyles,
  CustomSelect,
  Option,
} from '../components/CustomSelect/CustomSelect';
import AsyncSelect from 'react-select/async';
import { useAppDispatch } from '../../store/hooks';
import {
  fetchGetCompaniesByUser,
  fetchGetCompaniesCertificates,
  fetchGetCompanyByToken,
} from '../../API/company';
import { CompanyOption } from '../../structure/models/company/company';
import {
  makeCompanyOptionFromCompanies,
  makeCompanyOptionFromCompany,
} from './helpers/company';
import { BaseButtonSubmit } from '../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';
import { validators } from './validations';
import {
  Certificate,
  CertificateOption,
} from '../../structure/models/certificates/certificates';
import { makeCertificatesOptionFromCertificates } from './helpers/certificates';
import { MultiValue, SingleValue } from 'react-select';
import classNames from 'classnames';
import { fetchTransfer } from '../../API/transfer';
import { getTransferDataFromFormData } from './helpers/transfer';
import { useFormActions } from '../components/Form/FormHook';
import { validationFactory } from '../core/helpers/validationFactory';

const required = true;

const { createValidate } = validationFactory();

function isSingleValue(
  item: SingleValue<Option> | MultiValue<Option>,
): item is SingleValue<Option> {
  return !Array.isArray(item);
}

type FormDataFields = {
  volume?: string;
  buyer?: number;
  bcuid?: string;
  location?: string;
  date?: string;
};

export const Transfer: React.FC = React.memo(() => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<FormDataFields>({
    volume: '',
  });
  const [inputValue, setInputValue] = useState('');
  const [friendCompanies, setFriendCompanies] = useState<CompanyOption[]>([]);
  const [selectBuyer, setSelectBuyer] = useState<CompanyOption | null>(null);
  const [selectCertificate, setSelectCertificate] = useState<Certificate>();
  const [certificates, setCertificates] = useState<Certificate[]>([]);
  const [certificateOptions, setCertificateOptions] = useState<
    CertificateOption[]
  >([]);

  const validate = createValidate(validators, {
    volume: [selectCertificate?.volume],
  });

  const transformAndDispatchData = (formData: FormData) => {
    const transferData = getTransferDataFromFormData(formData);
    return dispatch(fetchTransfer(transferData));
  };

  const { loading, setLoading, onSubmit, showErrorsBlock } = useFormActions({
    validate,
    transformAndDispatchData,
  });

  const pastToken = useRef<boolean>(false);

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const name = target.name;
    const value = target.value;

    setFormData(old => ({
      ...old,
      [name]: value,
    }));
  };

  const loadCertificates = () => {
    return dispatch(fetchGetCompaniesCertificates()).then(data => {
      setCertificates(data);
      setCertificateOptions(makeCertificatesOptionFromCertificates(data));
    });
  };

  const loadFriends = () => {
    return dispatch(fetchGetCompaniesByUser()).then(data => {
      setFriendCompanies(makeCompanyOptionFromCompanies(data));
    });
  };

  useEffect(() => {
    Promise.all([loadFriends(), loadCertificates()]).then(() => {
      setLoading(false);
    });
  }, []);

  const onSelectCertificate = (option: Option | null) => {
    const value = option?.value;
    const cert = certificates.find(c => c.id === value);
    setSelectCertificate(cert);
  };

  const onChangeBuyer = (
    newValue: SingleValue<Option> | MultiValue<Option>,
  ) => {
    if (isSingleValue(newValue)) {
      setSelectBuyer(newValue);
    }
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: CompanyOption[]) => void,
  ) => {
    if (inputValue.startsWith('RSB000')) {
      dispatch(fetchGetCompanyByToken(inputValue))
        .then(data => {
          callback([makeCompanyOptionFromCompany(data)]);
          pastToken.current = true;
        })
        .catch(() => {
          pastToken.current = false;
        });
    } else {
      pastToken.current = false;
      callback(
        friendCompanies.filter(i =>
          i.label.toLowerCase().includes(inputValue.toLowerCase()),
        ),
      );
    }
  };

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, '');
    setInputValue(inputValue);
    return inputValue;
  };

  const buyer = (
    <AsyncSelect
      name={'buyer'}
      styles={colourStyles}
      placeholder={'Company name buyer *'}
      cacheOptions
      inputValue={inputValue}
      isClearable={true}
      loadOptions={loadOptions}
      defaultOptions={friendCompanies}
      onInputChange={handleInputChange}
      onChange={onChangeBuyer}
    />
  );

  const lockSubmit = !selectCertificate || !selectBuyer || loading;

  return (
    <div>
      <UserBalance />
      <div className="transfer_container">
        <form onSubmit={onSubmit}>
          <div>
            <h1>BCU Transfer</h1>
            <Row>
              <Col>
                <CustomSelect
                  name={'bcuid'}
                  onChange={onSelectCertificate}
                  placeholder={'BCU ID'}
                  options={certificateOptions}
                />
              </Col>
              <Col>
                <CustomInput
                  placeholder={'Volume of credit transferred'}
                  name={'volume'}
                  type={'number'}
                  lock={!selectCertificate}
                  onChange={onChange}
                  value={formData?.volume}
                  required={required}
                  error={showErrorsBlock['number']}
                />
              </Col>
            </Row>
            <Row>
              <Col>{buyer}</Col>
            </Row>
            <Row>
              <Col>
                <CustomInput
                  placeholder={'Location where SAF is uplifted'}
                  name={'location'}
                  type={'text'}
                  required={required}
                  error={showErrorsBlock['location']}
                />
              </Col>
              <Col>
                <CustomInput
                  placeholder={'Date when SAF is uplifted'}
                  name={'date'}
                  type={'text'}
                  onFocus={event => (event.target.type = 'date')}
                  onBlur={event => (event.target.type = 'text')}
                  required={required}
                  error={showErrorsBlock['date']}
                />
              </Col>
            </Row>
          </div>
          <div className="actions">
            <BaseButtonSubmit
              lock={lockSubmit}
              className={classNames({
                base_color: !lockSubmit,
              })}
            >
              Submit
            </BaseButtonSubmit>
          </div>
        </form>
      </div>
    </div>
  );
});
