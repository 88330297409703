import { FormError } from '../models/interfaces/formError';

type AdditionalArguments = {
  [key: string]: any[];
};

export type Validator<TValue = string> = (
  key: string,
  value: TValue,
  allEntries: [string, FormDataEntryValue][],
  ...rest: any[]
) => true | FormError;

export type Validators = {
  [key: string]: Validator<any>;
};
export type AllEntries = [string, FormDataEntryValue][];
export const validationFactory = () => {
  let code = 0;

  const createValidator = <TValue = string>(
    validExpression: (
      value: TValue,
      allEntries: AllEntries,
      ...rest: any[]
    ) => boolean,
    errorDescription?:
      | ((
          key: string,
          value: TValue,
          allEntries: AllEntries,
          ...rest: any[]
        ) => string)
      | string,
  ): Validator<TValue> => {
    code++;

    return (
      key: string,
      value: TValue,
      allEntries: [string, FormDataEntryValue][],
      ...rest: any[]
    ) => {
      if (validExpression(value, allEntries, ...rest)) {
        return true;
      }

      const description: string | undefined =
        errorDescription instanceof Function
          ? errorDescription(key, value, allEntries, ...rest)
          : errorDescription;

      return {
        key,
        code,
        description,
      };
    };
  };

  const createValidate =
    (validators: Validators, additionalArguments?: AdditionalArguments) =>
    (
      entries: IterableIterator<[string, FormDataEntryValue]>,
    ): (true | FormError)[] => {
      const entriesArray = Array.from(entries);

      return Object.keys(validators).map((key: string) => {
        const [, value] =
          entriesArray.find(([entryKey]) => entryKey === key) || [];

        return validators[key](
          key,
          value,
          entriesArray,
          ...(additionalArguments?.[key] || []),
        );
      });
    };

  return { createValidator, createValidate };
};
