import React from 'react';
import { Header } from '../components/Header/Header';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

export const MainLayout: React.FC = () => (
  <div>
    <Header />
    <Container>
      <Outlet />
    </Container>
  </div>
);
