import {
  ADMIN_CREDITS,
  ADMIN_LOGS,
  ADMIN_SUBMISSIONS_LIST,
  ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES,
  ADMIN_USERS_AND_TRANSACTIONS,
  DASHBOARD_ROUTES,
  STATEMENTS,
} from '../../../../structure/routes/routes';

export const TABS = [
  { path: STATEMENTS, title: 'Statements' },
  // user tabs
  { path: DASHBOARD_ROUTES.TRANSACTIONS, title: 'Dashboard' },
  { path: DASHBOARD_ROUTES.REGISTER_CREDIT, title: 'BCU Registration' },
  { path: DASHBOARD_ROUTES.TRANSFER, title: 'BCU Transfer' },
  { path: DASHBOARD_ROUTES.RETIREMENT, title: 'BCU Retirement' },
  // admin tabs
  { path: ADMIN_CREDITS, title: 'Credits' },
  { path: ADMIN_LOGS, title: 'Logs' },
  { path: ADMIN_USERS_AND_TRANSACTIONS, title: 'Users & Transactions' },
  {
    path: ADMIN_SUBMISSIONS_LIST,
    title: 'Submissions List',
    childRoutes: [
      ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.ADMIN_SUBMISSIONS_LIST_USERS_INFO,
      ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.ADMIN_SUBMISSIONS_LIST_COMPANY_INFO,
    ],
  },
];
