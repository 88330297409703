import React, { useState } from 'react';
import { CustomInput, CustomInputProps } from './Input';
import classNames from 'classnames';

import './PasswordStyle.scss';

interface Props extends Omit<CustomInputProps, 'someAfter' | 'type'> {}

export const Password: React.FC<Props> = ({ className, ...props }: Props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const switchShowPassword = () => setShowPassword(!showPassword);

  const eye = (
    <span
      className="material-symbols-outlined password-input__password-visibility"
      onClick={switchShowPassword}
    >
      visibility{!showPassword ? '_off' : ''}
    </span>
  );

  return (
    <CustomInput
      className={classNames(className, 'password-input')}
      someAfter={eye}
      type={showPassword ? 'text' : 'password'}
      {...props}
    />
  );
};
