import React from 'react';
import { UserInfo } from '../components/Profiles/UserInfo/UserInfo';
import { useAppSelector } from '../../store/hooks';
import { selectUser } from '../../store/reducers/user/userReducer';

export const UserInfoProfile: React.FC = () => {
  const user = useAppSelector(selectUser);

  return <UserInfo users={[user!]} company={user!.company} />;
};
