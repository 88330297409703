export const ANY = '*';
export const STATEMENTS = '/';

const subRoute = (mainRoute: string, subRoute: string) =>
  `${mainRoute}/${subRoute}`;

export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const REGISTRATION = '/registration';
export const CHANGE_PASSWORD = '/change-password';
export const VERIFY_EMAIL = '/verify-email';

export const DASHBOARD = '/dashboard';

export const TRANSFER = 'transfer';
export const LOGS = 'logs';
export const RETIREMENT = 'retirement';
export const REGISTER_CREDIT = 'register-credit';

export const DASHBOARD_ROUTES = {
  TRANSFER: subRoute(DASHBOARD, TRANSFER),
  LOGS: subRoute(DASHBOARD, LOGS),
  TRANSACTIONS: DASHBOARD,
  RETIREMENT: subRoute(DASHBOARD, RETIREMENT),
  REGISTER_CREDIT: subRoute(DASHBOARD, REGISTER_CREDIT),
};

// profile
export const USER_PROFILE = '/profile';
export const COMPANY_PROFILE = 'company';

export const PROFILE_ROUTES = {
  USER_PROFILE: USER_PROFILE,
  COMPANY_PROFILE: subRoute(USER_PROFILE, COMPANY_PROFILE),
};

// admin
const ADMIN = '/admin';
export const ADMIN_CREDITS = subRoute(ADMIN, 'credits');
export const ADMIN_LOGS = subRoute(ADMIN, 'logs');
export const ADMIN_USERS_AND_TRANSACTIONS = subRoute(
  ADMIN,
  'users-and-transactions',
);

export const ADMIN_SUBMISSIONS_LIST = subRoute(ADMIN, 'submission-lists');

export const ADMIN_SUBMISSIONS_LIST_COMPANY_INFO = subRoute(
  ADMIN_SUBMISSIONS_LIST,
  'company-info/:id',
);

export const ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES = {
  ADMIN_SUBMISSIONS_LIST_COMPANY_INFO,
  ADMIN_SUBMISSIONS_LIST_USERS_INFO: subRoute(
    ADMIN_SUBMISSIONS_LIST_COMPANY_INFO,
    'users',
  ),
};
