import {
  AllEntries,
  validationFactory,
  Validators,
} from '../core/helpers/validationFactory';

const { createValidator } = validationFactory();
export const validators: Validators = {
  volume: createValidator(
    (value: number, _: AllEntries, max?: number) =>
      !!(max && value > 0 && value <= max),
    (key, value, _: AllEntries, max: number) =>
      `Volume is so match, max is ${max}`,
  ),
  buyer: createValidator(
    value => value && value.toString() !== '',
    'Buyer was not selected',
  ),
};
