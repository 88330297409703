import React, { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { CustomSelect, Option } from '../CustomSelect/CustomSelect';
import { CustomInput, CustomInputProps } from './Input';
import { CODES } from './models/codes.constant';
import { SingleValue } from 'react-select';
import classNames from 'classnames';

import './PhoneStyle.scss';

export interface PhoneValue {
  code?: string;
  phone?: number;
}

interface Props extends Omit<CustomInputProps, 'type' | 'value' | 'onChange'> {
  value?: PhoneValue;
  onChange?: (e: PhoneValue) => void;
}

export const Phone: React.FC<Props> = React.memo(
  ({ name, className, error, value, onChange, ...props }) => {
    const [phoneValue, setPhoneValue] = useState<string | undefined>(
      value?.phone?.toString() || '',
    );
    const [codeValue, setCodeValue] = useState<string>(value?.code || '');

    useEffect(() => {
      setPhoneValue(value?.phone?.toString() || '');
      setCodeValue(value?.code || '');
    }, [value]);

    const onChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
      const input = event.currentTarget.value.trim();
      const phone = +input;
      if (!isNaN(phone)) {
        setPhoneValue(input);
        onChange?.({ ...value, phone: input === '' ? undefined : phone });
      }
    };

    const onChangeCode = (option: SingleValue<Option>) => {
      setCodeValue(option!.value);
      onChange?.({ ...value, code: option!.value });
    };

    return (
      <Row className={classNames(className, 'custom-phone')}>
        <Col md="3" sm="4" className="custom-phone__code-container">
          <CustomSelect
            placeholder="Code"
            options={CODES}
            value={
              codeValue ? { value: codeValue, label: codeValue } : undefined
            }
            onChange={onChangeCode}
          />
        </Col>
        <Col>
          <CustomInput
            type="tel"
            {...props}
            value={phoneValue}
            onChange={onChangePhone}
          />
        </Col>
        <input
          type="hidden"
          name={name}
          value={`${codeValue || ''}${phoneValue || ''}`}
        />
        {error && <div className="custom-phone__error">{error}</div>}
      </Row>
    );
  },
);
