import { ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES } from '../../../../../structure/routes/routes';

export const COMPANY_TABS = [
  {
    title: 'Company info',
    path: ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.ADMIN_SUBMISSIONS_LIST_COMPANY_INFO,
  },
  {
    title: 'Users info',
    path: ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.ADMIN_SUBMISSIONS_LIST_USERS_INFO,
  },
];
