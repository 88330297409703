import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';

export const UserDashboard: React.FC = React.memo(() => {
  return (
    <div>
      <Container>
        <Outlet />
      </Container>
    </div>
  );
});
