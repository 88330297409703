import React from 'react';
import Logo from '../../../assets/RSB-Logo.png';
import { Link } from 'react-router-dom';
import { NavigationTabs } from '../NavigationTabs/NavigationTabs';
import { HeaderActions } from './components/HeaderActions/HeaderActions';
import { TABS } from './models/tabs.constant';

import './HeaderStyle.scss';

export const Header: React.FC = React.memo(() => {
  return (
    <header className="header">
      <div className="main-header">
        <div className="main-header__company-name">
          <span>RSB Book & Calm Registry</span>
        </div>
        <HeaderActions />
      </div>
      <div className="header__navigation">
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        <NavigationTabs tabs={TABS} />
      </div>
    </header>
  );
});
