import React, { useEffect, useState } from 'react';
import { Modal, ModalProps } from '../../../Modal/Modal';
import { Col, Row } from 'react-bootstrap';
import { CustomInput } from '../../../Form/Input';
import { BaseButtonSubmit } from '../../../Buttons/BaseButtonSubmit/BaseButtonSubmit';
import classNames from 'classnames';
import { fetchGenerateToken } from '../../../../../API/token';
import { useAppDispatch } from '../../../../../store/hooks';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import './GenerateTokenModalStyle.scss';
import { toast } from 'react-toastify';

export const GenerateTokenModal: React.FC<ModalProps> = ({
  show,
  className,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const [token, setToken] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchToken = () => {
      setLoading(true);
      dispatch(fetchGenerateToken())
        .then(generatedToken => {
          setToken(generatedToken);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    show ? fetchToken() : setToken('');
  }, [show]);

  const onCopy = (_: string, success: boolean) => {
    if (success) {
      toast.success('Success: token has copied to clipboard.');
    }
  };
  return (
    <Modal
      show={show}
      header={<h2>Generate token</h2>}
      className={classNames('generate-token-modal', className)}
      centered
      {...props}
    >
      <Row>
        <Col md="9">
          <CustomInput placeholder="Token" disabled value={token} />
        </Col>
        <Col md="3">
          <CopyToClipboard text={token} onCopy={onCopy}>
            <BaseButtonSubmit active loading={loading}>
              Copy
            </BaseButtonSubmit>
          </CopyToClipboard>
        </Col>
      </Row>
    </Modal>
  );
};
