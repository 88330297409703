import React from 'react';
import {
  NavigationTabs,
  NavigationTabsProps,
} from '../components/NavigationTabs/NavigationTabs';
import { Outlet } from 'react-router-dom';

import './PageWithTabsLayoutStyle.scss';

interface Props extends NavigationTabsProps {
  underWrapper?: React.ReactElement;
  aboveWrapper?: React.ReactElement;
}

export const PageWithTabsLayout: React.FC<Props> = ({
  underWrapper,
  aboveWrapper,
  ...props
}) => {
  return (
    <div className="tabbed-page">
      {aboveWrapper}
      <div className="tabbed-page-wrapper">
        <div className="tabbed-page__navigation">
          <NavigationTabs {...props} replace />
        </div>
        <div className="tabbed-page__info">
          <Outlet />
        </div>
      </div>
      {underWrapper}
    </div>
  );
};
