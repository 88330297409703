import {
  Certificate,
  ProductType,
  Retirement,
} from '../structure/models/certificates/certificates';
import { GenerateFakeCompany } from './conpany';

export const FAKE_CERTIFICATE: Certificate = {
  id: 1,
  bcuid: 'BCU_ID_001',
  volume: 100,
  type: ProductType.SAF,
};

export const generateFakeCertificates = (count: number): Certificate[] => {
  return Array.from({ length: count }).map((_, index) => {
    return {
      id: index,
      bcuid: `#BCU_ID_00${index}`,
      volume: 100 * (index + 1),
      type: ProductType.SAF,
    };
  });
};

export const GenerateFakeRetirements = (index: number): Retirement => ({
  rsid: `ISCC-PLUS-Cert-ES216- 000${index}`,
  certificate: FAKE_CERTIFICATE,
  date: new Date().toISOString(),
  seller: GenerateFakeCompany(index),
  buyer: GenerateFakeCompany(100 - index),
  link: 'link_toDownload',
  volume: (index + 1) * 5,
});
