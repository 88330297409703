import React from 'react';
import classNames from 'classnames';

interface Props
  extends React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    React.AriaAttributes {
  lock?: boolean;
}

export const CleanSubmitButton: React.FC<React.PropsWithChildren<Props>> =
  React.memo(({ className, children, lock, onClick, ...props }) => {
    const customOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (lock) {
        e.preventDefault();
        return;
      }
      onClick?.(e);
    };

    return (
      <button
        className={classNames(className, {
          clear_submit_button_lock: lock,
        })}
        {...props}
        onClick={customOnClick}
      >
        {children}
      </button>
    );
  });
