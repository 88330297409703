import React, { useLayoutEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { selectToken, selectUser } from './store/reducers/user/userReducer';
import { refreshToken } from './API/login';
import { fetchUserData } from './API/user';
import { BigLoader } from './app/components/BigLoader/BigLoader';
import { ToastContainer } from 'react-toastify';
import { AppRoutes } from './AppRoutes';

function App() {
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  useLayoutEffect(() => {
    if (token) {
      dispatch(fetchUserData()).finally(() => {
        setFirstLoad(false);
      });
    }
  }, [token]);

  useLayoutEffect(() => {
    if (!user) {
      dispatch(refreshToken()).catch(() => {
        setFirstLoad(false);
      });
    }
  }, [user]);

  if (firstLoad) {
    return <BigLoader />;
  }

  return (
    <div id="App">
      <ToastContainer />
      <AppRoutes />
    </div>
  );
}

export default App;
