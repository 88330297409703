import React from 'react';
import './CustomSelectStyle.scss';
import classNames from 'classnames';
import Select, { GroupBase, StylesConfig } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

export interface Option {
  value: any;
  label: string;
}

export const colourStyles: StylesConfig<Option> = {
  control: (styles, { menuIsOpen }) => ({
    ...styles,
    backgroundColor: `rgba(69, 90, 100, 0.1)`,
    borderRadius: '5px',
    border: 'none',
    borderColor: 'transparent !important',
    boxShadow: 'none',
    minHeight: '40px',
  }),
  singleValue: styles => ({
    ...styles,
    color: '#455A64',
    paddingLeft: '6px',
  }),
};

type Props<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = StateManagerProps<Option, IsMulti, Group> & {
  error?: string;
  required?: boolean;
};

export function CustomSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  error,
  required = false,
  placeholder: rawPlaceholder,
  ...props
}: Props<Option, IsMulti, Group>) {
  const { className } = props;
  const placeholder = rawPlaceholder
    ? `${rawPlaceholder} ${required ? ' *' : ''}`
    : undefined;
  console.log({ props });
  return (
    <div className="custom_select__wrapper">
      <Select
        // @ts-ignore
        styles={colourStyles}
        className={classNames('custom_select', className, {
          custom_select_lock: props.isDisabled,
        })}
        placeholder={placeholder}
        {...props}
      />
      {error && <div className="custom_select__error">{error}</div>}
    </div>
  );
}
