import React, { useEffect, useMemo, useState } from 'react';
import './Pagination.scss';
import classNames from 'classnames';
import { CleanButton } from '../../Buttons/CleanButton/CleanButton';

type Props = {
  total: number;
  page: number;
  limit: number;
  changePage: (page: number) => void;
  lock: boolean;
};

export const PaginationNavigator: React.FC<Props> = React.memo(
  ({ total, page, changePage, limit = 10, lock }) => {
    const maxPages = Math.ceil(total / limit) || 1;

    const onClickToOne = () => {
      if (page !== 1) {
        changePage(1);
      }
    };

    const onClickToPrev = () => {
      if (page > 1) {
        changePage(page - 1);
      }
    };

    const onClickToNext = () => {
      if (page < maxPages) {
        changePage(page + 1);
      }
    };

    const onClickToLast = () => {
      if (page !== maxPages) {
        changePage(maxPages);
      }
    };

    const pagesString = useMemo(() => {
      let end = page * limit;
      const start = end - limit + 1;
      if (end > total) {
        end = total === 0 ? 1 : total;
      }

      return (
        <div className="pagination_navigation__element string">
          {`${start}-${end} of ${maxPages}`}
        </div>
      );
    }, [limit, page, total]);

    return (
      <div
        className={classNames('pagination_navigation', {
          lock,
        })}
      >
        {pagesString}
        <CleanButton
          lock={lock || page === 1}
          className="pagination_navigation__element"
          onClick={onClickToOne}
        >
          <span className="material-symbols-outlined">
            keyboard_double_arrow_left
          </span>
        </CleanButton>
        <CleanButton
          lock={lock || page === 1}
          className="pagination_navigation__element"
          onClick={onClickToPrev}
        >
          <span className="material-symbols-outlined">keyboard_arrow_left</span>
        </CleanButton>

        <CleanButton
          lock={lock || page === maxPages}
          className="pagination_navigation__element"
          onClick={onClickToNext}
        >
          <span className="material-symbols-outlined">
            keyboard_arrow_right
          </span>
        </CleanButton>
        <CleanButton
          lock={lock || page === maxPages}
          className="pagination_navigation__element"
          onClick={onClickToLast}
        >
          <span className="material-symbols-outlined">
            keyboard_double_arrow_right
          </span>
        </CleanButton>
      </div>
    );
  },
);
