import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PaginationRequestData } from '../../../../structure/request/pagination';
import { PaginationNavigator } from './PaginationNavigator';
import { ShowBy } from './ShowBy';
import { useSearchParams } from 'react-router-dom';
import { SortOrder, SortOrderD } from '../SortItem/SortItem';
import { Column } from '../Table';
import { Action } from 'redux';
import { FetchData } from './models/fetch-data.type';
import { Filters } from './models/filters.type';
import { PaginationHookProps } from './models/pagination-hook-props.interface';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useDispatch } from 'react-redux';

type Props = {
  url: string;
  name?: string;
};

const getSearchParam = (
  key: string,
  defaultValue: string | number | null | undefined,
): typeof defaultValue => {
  const searchParams = new URLSearchParams(window.location.search);
  let value: string | number | null | undefined = searchParams.get(key);

  if (typeof defaultValue === 'number') {
    value = Number.isNaN(+(value ?? NaN)) ? null : +value!;
  }

  if (defaultValue === null || defaultValue === undefined) {
    value = value === 'null' || value === 'undefined' ? defaultValue : value;
  }

  return value ?? defaultValue;
};

export const useSearchParamsState = <
  T extends string | number | null | undefined,
>(
  key: string,
  initialState: T,
): [T, (value: T) => void] => {
  const [_, setSearchParams] = useSearchParams();
  const [state, setState] = useState<T>(getSearchParam(key, initialState) as T);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const oldParams = Array.from(searchParams.entries()).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {},
    );

    setSearchParams({ ...oldParams, [key]: `${state}` }, { replace: true });
  }, [state]);

  return [state, setState];
};

const usePagination = (defaultSortBy: string) => {
  const [page, setPage] = useSearchParamsState<number>('page', 1);
  const [limit, setLimit] = useSearchParamsState<number>('limit', 10);
  const [sortBy, setSortBy] = useSearchParamsState<string>(
    'sortBy',
    defaultSortBy,
  );
  const [sortOrder, setSortOrder] = useSearchParamsState<SortOrderD>(
    'sortOrder',
    'asc',
  );

  return {
    page,
    setPage,
    limit,
    setLimit,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
  };
};

export function usePaginator<TData>({
  selectStatus,
  fetchData,
  selectData,
  filters,
  columns,
  defaultSortBy,
}: PaginationHookProps<TData>) {
  const {
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    page,
    setPage,
    limit,
    setLimit,
  } = usePagination(defaultSortBy);

  const dispatch = useAppDispatch();
  const data = useAppSelector(selectData);
  const status = useAppSelector(selectStatus);
  const lock = status === 'loading';

  useEffect(() => {
    dispatch(
      fetchData({
        sortOrder,
        sortBy,
        page,
        limit,
        ...(filters || {}),
      }),
    );
  }, [sortOrder, sortBy, page, limit, filters, columns]);

  const toggleSortOrder = () => (sortOrder === 'asc' ? 'desc' : 'asc');

  const onChangeSort = (key: string) => {
    setSortOrder(key !== sortBy ? 'asc' : toggleSortOrder());
    setSortBy(key);
  };

  const changePage = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const changeLimit = (value: number) => {
    const startIndex = (page - 1) * value;

    if (startIndex >= data.count) {
      setPage(Math.ceil((startIndex % data.count) / value) + 1);
    }

    setLimit(value);
  };

  const paginationNavigation = (
    <PaginationNavigator
      changePage={changePage}
      limit={limit}
      page={page}
      total={data.count}
      lock={lock}
    />
  );

  const showBy = <ShowBy limit={limit} changeLimit={changeLimit} />;

  return {
    data,
    paginationNavigation,
    showBy,
    setLimit,
    loading: lock,
    sort: { sortBy, sortOrder },
    onChangeSort,
  };
}
