import { Column } from '../../../../components/Table/Table';
import { SubmissionsUsers } from '../../../../../structure/models/admin/submissions';
import {
  SubmissionsStatus,
  SubmissionsStatusBE,
  SubmissionsType,
} from '../../../../../structure/request/admin';
import classNames from 'classnames';
import { DateTime } from 'luxon';

const STATUSES = {
  [SubmissionsStatusBE.Approved]: SubmissionsStatus.Approved,
  [SubmissionsStatusBE.Declined]: SubmissionsStatus.Rejected,
  [SubmissionsStatusBE.Pending]: SubmissionsStatus.Consideration,
};

export const REGISTRATION_COLUMNS: Column<SubmissionsUsers>[] = [
  {
    title: 'Date and Time',
    keyItem: 'createdAt',
    sort: true,
    render: item =>
      DateTime.fromISO(item.createdAt).toFormat(`dd.LL.yyyy 'at'  HH:mm`),
  },
  {
    title: 'Type Of Request',
    keyItem: 'typeOfRequest',
    render: () => SubmissionsType.Registration,
  },
  {
    title: 'SUID',
    keyItem: 'SUID',
    render: item => item.target.SUID,
  },
  {
    title: 'User Name',
    keyItem: 'userName',
    render: item => `${item.target.firstname} ${item.target.lastname}`,
  },
  {
    title: 'Company Name',
    keyItem: 'companyName',
    render: item => item.target.company.name,
  },
  {
    title: 'Status',
    keyItem: 'status',
    render: item => (
      <span
        className={classNames('submissions-list__status', {
          approved: item.status === SubmissionsStatusBE.Approved,
          declined: item.status === SubmissionsStatusBE.Declined,
          consideration: item.status === SubmissionsStatusBE.Pending,
        })}
      >
        {item.status === SubmissionsStatusBE.Pending
          ? 'For Consideration'
          : STATUSES[item.status]}
      </span>
    ),
  },
];
