import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { selectUser } from '../../store/reducers/user/userReducer';
import { AdminDashboard } from './AdminDashboard/AdminDashboard';
import { UserDashboard } from './UserDashboard/UserDashboard';
import { Role } from '../../structure/models/user/user';

export const Dashboards: React.FC = React.memo(() => {
  const user = useAppSelector(selectUser);
  console.log('dashboard', user?.role);
  switch (user?.role) {
    case Role.Admin:
      return <AdminDashboard />;
    case Role.SystemUser:
      return <UserDashboard />;
    default:
      return null;
  }
});
