import {
  Company,
  CompanyOption,
} from '../../../structure/models/company/company';

export function makeCompanyOptionFromCompany(company: Company): CompanyOption {
  return {
    value: company.id,
    label: company.name,
  };
}

export function makeCompanyOptionFromCompanies(
  company: Company[] | undefined,
): CompanyOption[] {
  if (!company) {
    return [];
  }
  return company.map(makeCompanyOptionFromCompany);
}
