import React, { FC, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  DASHBOARD,
  STATEMENTS,
  LOGIN,
  LOGS,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  REGISTRATION,
  RETIREMENT,
  TRANSFER,
  ANY,
  DASHBOARD_ROUTES,
  REGISTER_CREDIT,
  USER_PROFILE,
  COMPANY_PROFILE,
  PROFILE_ROUTES,
  ADMIN_CREDITS,
  ADMIN_LOGS,
  ADMIN_USERS_AND_TRANSACTIONS,
  ADMIN_SUBMISSIONS_LIST,
  VERIFY_EMAIL,
  ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES,
} from './structure/routes/routes';
import { Dashboards } from './app/dashboards/Dashboards';
import { Transactions } from './app/transactions/Transactions/Transactions';
import { Logs } from './app/logs/Logs';
import { Transfer } from './app/transfer/Transfer';
import { Retirement } from './app/retirement/Retirement/Retirement';
import { Home } from './app/home/Home';
import { ChangePassword } from './app/login/ChangePassword';
import { Registration } from './app/registration/Registration';
import { ForgotPassword } from './app/login/ForgotPassword';
import { Login } from './app/login/Login';
import { guard } from './app/core/HOC/guard';
import { pageAccesses } from './app/core/models/contstants/page-accesses';
import { MainLayout } from './app/layouts/MainLayout';
import { Profiles } from './app/profiles/Profiles';
import { UserInfo } from './app/components/Profiles/UserInfo/UserInfo';
import { CompanyInfo } from './app/components/Profiles/CompanyInfo/CompanyInfo';
import { BCURegistration } from './app/dashboards/UserDashboard/BCURegistration/BCURegistration';
import { SubmissionList } from './app/dashboards/AdminDashboard/SubmissionList/SubmissionList';
import { EmailVerification } from './app/email-verification/EmailVerification';
import { AdminCompanyInfoLayout } from './app/dashboards/AdminDashboard/SubmissionList/AdminCompanyInfoLayout';
import { AdminCompanyInfo } from './app/dashboards/AdminDashboard/SubmissionList/AdminCompanyInfo';
import { AdminUsersInfo } from './app/dashboards/AdminDashboard/SubmissionList/AdminUsersInfo';
import { UserInfoProfile } from './app/profiles/UserInfoProfile';
import { CompanyInfoProfile } from './app/profiles/CompanyInfoProfile';

const GuardedDashboards = guard(Dashboards);
const GuardedTransactions = guard(Transactions);
const GuardedLogs = guard(Logs);
const GuardedBCURegistration = guard(BCURegistration);
const GuardedTransfer = guard(Transfer);
const GuardedRetirement = guard(Retirement);
const GuardedSetPassword = guard(ChangePassword);
const GuardedRegistration = guard(Registration);
const GuardedForgotPassword = guard(ForgotPassword);
const GuardedLogin = guard(Login);
const GuardedProfiles = guard(Profiles);
const GuardedCompanyInfo = guard(CompanyInfoProfile);

const GuardedAdminCredits = guard(() => <></>);
const GuardedAdminLogs = guard(() => <></>);
const GuardedAdminUsersAndTransactions = guard(() => <></>);
const GuardedAdminSubmissionsList = guard(SubmissionList);
const GuardedAdminCompanyInfoLayout = guard(AdminCompanyInfoLayout);
const GuardedAdminCompanyInfo = guard(AdminCompanyInfo);
const GuardedAdminUsersInfo = guard(AdminUsersInfo);

const {
  [DASHBOARD]: dashboardAccess,
  [DASHBOARD_ROUTES.LOGS]: logsAccess,
  [DASHBOARD_ROUTES.TRANSFER]: transferAccess,
  [DASHBOARD_ROUTES.RETIREMENT]: retirementAccess,
  [DASHBOARD_ROUTES.REGISTER_CREDIT]: registerCreditAccess,
  [CHANGE_PASSWORD]: setPasswordAccess,
  [REGISTRATION]: registrationAccess,
  [FORGOT_PASSWORD]: forgotPasswordAccess,
  [LOGIN]: loginAccess,
  [PROFILE_ROUTES.USER_PROFILE]: userInfoAccess,
  [PROFILE_ROUTES.COMPANY_PROFILE]: companyInfoAccess,
  [ADMIN_CREDITS]: adminCreditsAccess,
  [ADMIN_LOGS]: adminLogsAccess,
  [ADMIN_USERS_AND_TRANSACTIONS]: adminUsersAndTransactionsAccess,
  [ADMIN_SUBMISSIONS_LIST]: adminSubmissionListAccess,
  [ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.ADMIN_SUBMISSIONS_LIST_COMPANY_INFO]:
    adminSubmissionsCompanyInfoAccess,
  [ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.ADMIN_SUBMISSIONS_LIST_USERS_INFO]:
    adminSubmissionsUsersInfoAccess,
} = pageAccesses;

export const AppRoutes: FC = React.memo(() => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route
          path={DASHBOARD}
          element={<GuardedDashboards {...dashboardAccess} />}
        >
          <Route index element={<GuardedTransactions {...dashboardAccess} />} />
          <Route path={LOGS} element={<GuardedLogs {...logsAccess} />} />
          <Route
            path={REGISTER_CREDIT}
            element={<GuardedBCURegistration {...registerCreditAccess} />}
          />
          <Route
            path={TRANSFER}
            element={<GuardedTransfer {...transferAccess} />}
          />
          <Route
            path={RETIREMENT}
            element={<GuardedRetirement {...retirementAccess} />}
          />
        </Route>
        <Route
          path={USER_PROFILE}
          element={<GuardedProfiles {...userInfoAccess} />}
        >
          <Route index element={<UserInfoProfile />} />
          <Route
            path={COMPANY_PROFILE}
            element={<GuardedCompanyInfo {...companyInfoAccess} />}
          />
        </Route>
        <Route
          path={CHANGE_PASSWORD}
          element={<GuardedSetPassword {...setPasswordAccess} />}
        />
        <Route
          path={REGISTRATION}
          element={<GuardedRegistration {...registrationAccess} />}
        />
        <Route
          path={FORGOT_PASSWORD}
          element={<GuardedForgotPassword {...forgotPasswordAccess} />}
        />
        <Route path={LOGIN} element={<GuardedLogin {...loginAccess} />} />
        <Route
          path={ADMIN_CREDITS}
          element={<GuardedAdminCredits {...adminCreditsAccess} />}
        />
        <Route
          path={ADMIN_LOGS}
          element={<GuardedAdminLogs {...adminLogsAccess} />}
        />
        <Route
          path={ADMIN_USERS_AND_TRANSACTIONS}
          element={
            <GuardedAdminUsersAndTransactions
              {...adminUsersAndTransactionsAccess}
            />
          }
        />
        <Route
          path={ADMIN_SUBMISSIONS_LIST}
          element={
            <GuardedAdminSubmissionsList {...adminSubmissionListAccess} />
          }
        />
        <Route
          path={
            ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.ADMIN_SUBMISSIONS_LIST_COMPANY_INFO
          }
          element={
            <GuardedAdminCompanyInfoLayout
              {...adminSubmissionsCompanyInfoAccess}
            />
          }
        >
          <Route
            index
            element={
              <GuardedAdminCompanyInfo {...adminSubmissionsCompanyInfoAccess} />
            }
          />
          <Route
            path={
              ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.ADMIN_SUBMISSIONS_LIST_USERS_INFO
            }
            element={
              <GuardedAdminUsersInfo {...adminSubmissionsUsersInfoAccess} />
            }
          />
        </Route>
        <Route path={VERIFY_EMAIL} element={<EmailVerification />} />
        <Route path={VERIFY_EMAIL} element={<EmailVerification />} />
        <Route path={STATEMENTS} element={<Home />} />
        <Route path={ANY} element={<Navigate to={STATEMENTS} />} />
      </Route>
    </Routes>
  );
});
