import React from 'react';
import { nameBySuffix } from './helpers/registration';
import { Suffixes } from '../../structure/request/registration';
import { CustomInput } from '../components/Form/Input';
import { Col, Row } from 'react-bootstrap';
import { ErrorBlocks } from '../components/Form/FormHook';
import { Phone } from '../components/Form/Phone';

type Props = {
  suffix: Suffixes;
  required?: boolean;
  showErrorBlocks: ErrorBlocks;
};

export const UserForm: React.FC<Props> = React.memo(
  ({ suffix, required, showErrorBlocks }) => {
    return (
      <Row>
        <Col md={6}>
          <CustomInput
            placeholder={'Name'}
            name={nameBySuffix('name', suffix)}
            type={'text'}
            required={required}
            error={showErrorBlocks[nameBySuffix('name', suffix)]}
          />
        </Col>
        <Col md={6}>
          <CustomInput
            placeholder={'Surname'}
            name={nameBySuffix('surname', suffix)}
            type={'text'}
            required={required}
            error={showErrorBlocks[nameBySuffix('surname', suffix)]}
          />
        </Col>
        <Col md={6}>
          <CustomInput
            placeholder={'Email'}
            name={nameBySuffix('email', suffix)}
            type={'email'}
            required={required}
            error={showErrorBlocks[nameBySuffix('email', suffix)]}
          />
        </Col>
        <Col md={6}>
          <Phone
            placeholder={'Cell phone'}
            name={nameBySuffix('phone', suffix)}
            required={required}
            error={showErrorBlocks[nameBySuffix('phone', suffix)]}
          />
        </Col>
        <Col md={6}>
          <CustomInput
            placeholder={'Position'}
            name={nameBySuffix('position', suffix)}
            type={'text'}
            required={required}
            error={showErrorBlocks[nameBySuffix('position', suffix)]}
          />
        </Col>
      </Row>
    );
  },
);
