import React from 'react';
import classNames from 'classnames';
import { SortItem, SortOrder } from './SortItem/SortItem';
import { usePaginator } from './Pagination/PaginationHook';
import { PaginationHookProps } from './Pagination/models/pagination-hook-props.interface';

import './TableStyle.scss';

export interface Column<T> {
  title?: string;
  sort?: boolean;
  sorter?: (a: T, b: T) => number;
  render: (item: T, index: number) => React.ReactElement | string;
  keyItem: string;
}

interface Props<T>
  extends React.DetailedHTMLProps<
    React.TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
  > {
  columns: Column<T>[];
  showHeader?: boolean;
  data: T[];
  rowClass?: string | ((item: T) => string);
  rowKey: (item: T) => string;
  paginator?: React.ReactElement;
  onSort?: (key: string) => void;
  sort?: SortOrder;
  loading?: boolean;
  onRowClick?: (item: T) => void;
}

interface PaginatedTableProps<TData = any>
  extends Omit<
      Props<TData>,
      'paginator' | 'onSort' | 'sort' | 'showHeader' | 'data'
    >,
    Omit<PaginationHookProps<TData>, 'defaultSortBy'> {}

export const Table = <T,>({
  className,
  showHeader,
  columns,
  data,
  rowKey,
  paginator,
  onSort,
  sort,
  loading = false,
  onRowClick,
  ...props
}: React.PropsWithChildren<Props<T>>) => {
  return (
    <div className="table-wrapper">
      <table className={classNames('base_table', className)} {...props}>
        {showHeader && (
          <thead>
            <tr>
              {columns.map((column: Column<T>) => (
                <th key={`column_${column.keyItem}`}>
                  {column.sort ? (
                    <SortItem
                      sort={sort!}
                      keyItem={column.keyItem}
                      title={column.title!}
                      onChangeSort={onSort!}
                    />
                  ) : (
                    column.title
                  )}
                </th>
              ))}
            </tr>
          </thead>
        )}

        <tbody>
          {!loading &&
            data.map((item: T, index: number) => (
              <tr
                className={classNames({
                  even: index % 2 === 0,
                  clickable: !!onRowClick,
                })}
                key={rowKey(item)}
                {...(onRowClick ? { onClick: () => onRowClick(item) } : {})}
              >
                {columns.map((column: Column<T>) => (
                  <td key={`column_${rowKey(item)}_${column.keyItem}`}>
                    <span className="text">{column.render(item, index)}</span>
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      {(loading || !data.length) && (
        <div className="table-service-information">
          {loading && (
            <div className="table-service-information__loading">
              <span className="material-symbols-outlined loading-icon">
                loop
              </span>
              Loading...
            </div>
          )}
          {!loading && !data.length && (
            <div className="table-service-information__no-result-found">
              <div className="table-service-information__no-result-found__icon">
                <span className="material-symbols-outlined">
                  sentiment_very_dissatisfied
                </span>
              </div>
              <div className="table-service-information__no-result-found__text">
                No result found
              </div>
            </div>
          )}
        </div>
      )}
      {paginator && <div className="table-pagination">{paginator}</div>}
    </div>
  );
};

export const PaginatedTable = <TData,>({
  columns,
  fetchData,
  filters,
  selectData,
  selectStatus,
  ...props
}: React.PropsWithChildren<PaginatedTableProps<TData>>) => {
  const firstSortColumn = columns.find((column: Column<TData>) => column.sort);
  const { data, sort, loading, onChangeSort, paginationNavigation, showBy } =
    usePaginator({
      fetchData,
      filters,
      columns,
      defaultSortBy: firstSortColumn?.keyItem ?? '',
      selectData,
      selectStatus,
    });

  return (
    <Table
      onSort={onChangeSort}
      paginator={
        <>
          {showBy}
          {paginationNavigation}
        </>
      }
      data={data.results}
      sort={sort}
      columns={columns}
      loading={loading}
      showHeader
      {...props}
    />
  );
};
