import React from 'react';
import { PaginationComponent } from '../components/PaginationComponent/PaginationComponent';
import { usePaginator } from '../components/Table/Pagination/PaginationHook';
import { Header } from '../components/Header/Header';
import { BaseButton } from '../components/Buttons/BaseButton/BaseButton';
import { useAppSelector } from '../../store/hooks';
import { selectUser } from '../../store/reducers/user/userReducer';
import { URL_GET_RETIREMENTS } from '../../API/urls/urls';
import { RetirementItemList } from '../retirement/RetirementItemList/RetirementItemList';
import { Container } from 'react-bootstrap';
import { SortItem } from '../components/Table/SortItem/SortItem';
import './HomeStyle.scss';
import { CustomInput } from '../components/Form/Input';
import { Table } from '../components/Table/Table';

export const Home: React.FC = () => {
  const user = useAppSelector(selectUser);
  // const { data, paginationNavigation, showBy, sort, onChangeSort } =
  //   usePaginator([], [], '');

  return (
    <div>
      <Container>
        <div className={'home_header'}>
          <h1>All Certificates Retirements </h1>
          <div className={'search'}>
            <CustomInput
              someBefore={
                <span className="search_icon material-symbols-outlined">
                  search
                </span>
              }
              onChange={() => {}}
              className={'search_input'}
              placeholder={'Search'}
              name={'search'}
              type={'text'}
            />
          </div>
        </div>
        <Table
          columns={[
            {
              title: 'Statement ID',
              keyItem: 'id',
              sort: false,
              render: () => '',
            },
            {
              title: 'Date of Issue',
              keyItem: 'date',
              sort: false,
              render: () => '',
            },
            {
              title: 'Product Type',
              keyItem: 'type',
              sort: false,
              render: () => '',
            },
            {
              title: 'Amount BCUs',
              keyItem: 'volume',
              sort: false,
              render: () => '',
            },
            {
              title: 'BCU ID',
              keyItem: 'BCU ID',
              sort: false,
              render: () => '',
            },
            {
              title: 'Retired By',
              keyItem: 'seller',
              sort: false,
              render: () => '',
            },
            {
              title: 'On Behalf Of',
              keyItem: 'buyer',
              sort: false,
              render: () => '',
            },
            {
              title: 'Certificate',
              keyItem: 'certificate',
              sort: false,
              render: () => '',
            },
          ]}
          data={[]}
          rowKey={() => '123'}
          showHeader
          className="retirement_table"
        />
      </Container>
    </div>
  );
};
