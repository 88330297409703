import React from 'react';
import './UserBalanceStyle.scss';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/reducers/user/userReducer';
import { Role } from '../../../structure/models/user/user';

export const UserBalance: React.FC = React.memo(() => {
  const user = useAppSelector(selectUser);
  if (user?.role === Role.Admin || user?.role === Role.SuperAdmin || !user) {
    return null;
  }
  return (
    <div className="user_balance">
      <div>
        Company: <b>{user.company.name}</b>
      </div>
      <div>
        Total balance: <b>{user.company.balance ?? 0} BCU</b>
      </div>
    </div>
  );
});
