import React from 'react';
import classNames from 'classnames';
import { CleanButton } from '../CleanButton/CleanButton';
import './BaseButtonStyle.scss';
import { Link } from 'react-router-dom';

interface PropsDiv
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  lock?: boolean;
  link?: string;
  active?: boolean;
}

type Props = React.PropsWithChildren<PropsDiv>;

export const BaseButton: React.FC<Props> = React.memo(
  ({ className, link, children, lock, active, ...props }) => {
    if (link) {
      return (
        <Link to={link} className={'base_button_link'}>
          <CleanButton
            {...props}
            className={classNames(
              'base_button',
              {
                active,
              },
              className,
            )}
          >
            {children}
          </CleanButton>
        </Link>
      );
    }
    return (
      <CleanButton {...props} className={classNames('base_button', className)}>
        {children}
      </CleanButton>
    );
  },
);
