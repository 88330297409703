import React from 'react';
import { UserBalance } from '../../../components/UserBalance/UserBalance';
import { Col, Row } from 'react-bootstrap';
import { CustomInput } from '../../../components/Form/Input';
import { CustomSelect } from '../../../components/CustomSelect/CustomSelect';
import { DatePicker } from '../../../components/Form/DatePicker';

export const BCURegistration: React.FC = () => {
  return (
    <div className="bcu-registration">
      <UserBalance />
      <div className="bcu-registration-form">
        <h2>BCU Registration</h2>
        <Row>
          <Row>
            <Col>
              <CustomSelect
                placeholder="Product type"
                required
                name="product_type"
              />
            </Col>
            <Col>
              <CustomInput
                placeholder="SAF Name Amount in MT"
                type="number"
                required
                name="amount_SAF"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CustomInput
                placeholder="Batch Number"
                type="number"
                required
                name="batch_number"
              />
            </Col>
            <Col>
              <CustomSelect
                placeholder="Raw material"
                required
                name="raw_material"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CustomInput
                placeholder="Credit volume"
                type="number"
                required
                name="credit_volume"
              />
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col>
              <DatePicker
                placeholder="Date of Acquisition"
                required
                name="acquisition_date"
              />
            </Col>
            <Col>
              <DatePicker
                placeholder="Date of Entry"
                required
                name="acquisition_date"
              />
            </Col>
          </Row>
        </Row>
        <Row>
          <Row>
            <DatePicker
                placeholder="Name of the last production/processing site"
                required
                name="name_of_last"
            />
          </Row>
        </Row>
      </div>
    </div>
  );
};
