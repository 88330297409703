import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchChangePassword, fetchSetPassword } from '../../API/login';
import { BaseButtonSubmit } from '../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';
import { useFormActions } from '../components/Form/FormHook';
import { getFormChangePasswordData, validate } from './helpers/change-password';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LOGIN, STATEMENTS } from '../../structure/routes/routes';
import { Password } from '../components/Form/Password';
import { selectToken } from '../../store/reducers/user/userReducer';

import './ChangePasswordStyle.scss';

export const ChangePassword: React.FC = React.memo(() => {
  const accessToken = useAppSelector(selectToken);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    if (!accessToken && !token) {
      toast('Error: Token is required.', { type: 'error' });
      navigate(STATEMENTS);
    }
  }, [accessToken]);

  const transformAndDispatchData = (formData: FormData) => {
    const data = getFormChangePasswordData(formData, token);
    const hasToken = 'token' in data;

    return dispatch(
      hasToken ? fetchSetPassword(data) : fetchChangePassword(data),
    ).then(() => {
      toast('Success: Password has been changed.', {
        type: 'success',
      });

      navigate(hasToken ? STATEMENTS : LOGIN);
    });
  };

  const { onSubmit, loading, showErrorsBlock } = useFormActions({
    validate,
    transformAndDispatchData,
  });

  return (
    <div className="change-password-screen">
      <div className="change-password-screen__form">
        <h1>Reset your password</h1>
        <form onSubmit={onSubmit}>
          <Password
            placeholder="New password"
            required
            name="password"
            error={showErrorsBlock['password']}
          />
          <div className="change-password-screen__form-description">
            Minimum 8 characters
          </div>
          <BaseButtonSubmit
            className="change-password-screen__form-submit"
            active
            lock={loading}
            loading={loading}
          >
            Reset Password
          </BaseButtonSubmit>
        </form>
      </div>
    </div>
  );
});
