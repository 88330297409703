import React from 'react';
import { ContactUs } from '../../../ContactUs/ContactUs';
import { UserMenu } from '../UserMenu/UserMenu';
import { BaseButton } from '../../../Buttons/BaseButton/BaseButton';
import { useAppSelector } from '../../../../../store/hooks';
import { selectUser } from '../../../../../store/reducers/user/userReducer';

export const HeaderActions: React.FC = () => {
  const user = useAppSelector(selectUser);

  return (
    <div className="main-header__actions">
      <ContactUs />
      {user && <UserMenu />}
      {!user && (
        <>
          <BaseButton link={'/registration'} active={true}>
            Sing Up
          </BaseButton>
          <BaseButton className="main-header__actions-login" link={'/login'}>
            Log In
          </BaseButton>
        </>
      )}
    </div>
  );
};
