import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ViewElement } from '../../ViewElement/ViewElement';
import {
  Company,
  COMPANY_TYPE_NAMES,
  CompanyType,
} from '../../../../structure/models/company/company';
import { DateTime } from 'luxon';

import './CompanyInfoStyle.scss';

const formatDate = (date: string | Date) => {
  return DateTime.fromJSDate(new Date(date)).toFormat('dd.LL.yyyy');
};

interface Props {
  company: Company;
  isAdmin: boolean;
}

export const CompanyInfo: React.FC<Props> = ({ company, isAdmin }) => {
  const certificateDate =
    company.type === CompanyType.Supplier
      ? `${formatDate(company.certificateValidityStartDate!)} - ${formatDate(
          company.certificateValidityEndDate!,
        )}`
      : null;

  return (
    <div className="company-info">
      {!isAdmin && (
        <Row className="company-info__header-block">
          <Col className="company-info__header-block__company">
            <h1>{company.name}</h1>
            <ViewElement
              label={COMPANY_TYPE_NAMES[company.type!]}
              dots={false}
            />
          </Col>
          <Col className="company-info__header-block__total-balance">
            <ViewElement
              label="Total Balance"
              description={`${company.balance || 0} BCU`}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col md="5" className="company-info__info-block">
          {isAdmin && (
            <>
              <ViewElement label="Company Name" description={company.name} />
              <ViewElement
                label="Type of organization"
                description={COMPANY_TYPE_NAMES[company.type!]}
              />
            </>
          )}
          <ViewElement
            label="RSB PO Number"
            description={company.RSBPONumber}
          />
          <ViewElement
            label="Certificate validity date"
            description={certificateDate}
          />
          <ViewElement
            label="Address"
            description={`${company.address} ${company.addressNumber} ${company.city}, ${company.country} ${company.zip}`}
          />
        </Col>
      </Row>
    </div>
  );
};
