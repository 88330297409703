import { AppThunk, AppThunkDispatch } from '../store/store';
import { actions } from './fetchProvider/fetch';
import {
  URL_ADMIN_APPROVE_USER_REGISTRATION,
  URL_ADMIN_GET_COMPANY_INFO,
  URL_ADMIN_GET_SUBMISSIONS_USERS,
  URL_ADMIN_REJECT_USER_REGISTRATION,
} from './urls/urls';
import {
  SubmissionsRequest,
  SubmissionsType,
} from '../structure/request/admin';
import axios, { AxiosResponse } from 'axios';
import { PaginatedData } from '../structure/request/pagination';
import { SubmissionsUsers } from '../structure/models/admin/submissions';
import { ServerResponse } from './types';
import {
  setStatus,
  setSubmissionsCompanyInfo,
  setSubmissionsUsers,
} from '../store/reducers/admin/submitssionsReducer';

const SUBMISSIONS_TYPES: Record<SubmissionsType, string> = {
  [SubmissionsType.Registration]: 'user-registration',
  [SubmissionsType.BCURegistration]: 'credit-transaction',
  [SubmissionsType.BCURetirement]: 'credit-transaction',
  [SubmissionsType.BCUTransfer]: 'credit-transaction',
};

export const fetchSubmissionsUsers =
  ({
    type,
    page,
    limit,
    ...query
  }: SubmissionsRequest): AppThunk<Promise<any>> =>
  (dispatch: AppThunkDispatch) => {
    dispatch(setStatus('loading'));

    return actions
      .get<AxiosResponse<ServerResponse<PaginatedData<SubmissionsUsers>>>>(
        URL_ADMIN_GET_SUBMISSIONS_USERS,
        {
          queries: {
            skip: (page - 1) * limit,
            limit,
            ...query,
          },
        },
      )
      .then(response => {
        dispatch(setStatus('idle'));
        dispatch(setSubmissionsUsers(response.data.data));

        return response.data.data;
      })
      .catch(e => {
        dispatch(setStatus('failed'));
        throw e;
      });
  };

export const fetchSubmissionsCompanyInfo =
  (id: string) => (dispatch: AppThunkDispatch) => {
    dispatch(setStatus('loading'));

    return actions
      .get<any>(URL_ADMIN_GET_COMPANY_INFO.replace(':id', id))
      .then(response => {
        dispatch(setStatus('idle'));
        dispatch(setSubmissionsCompanyInfo(response.data.data));

        return response.data.data;
      })
      .catch(e => {
        dispatch(setStatus('failed'));
        throw e;
      });
  };

export const fetchRegistrationReject =
  ({ id, message }: any) =>
  () => {
    return actions.post(URL_ADMIN_REJECT_USER_REGISTRATION.replace(':id', id), {
      payloads: { message },
    });
  };

export const fetchRegistrationApprove =
  ({ id }: any) =>
  () => {
    return actions.post(URL_ADMIN_APPROVE_USER_REGISTRATION.replace(':id', id));
  };
