import React from 'react';
import { CustomSelect } from '../CustomSelect/CustomSelect';
import { CustomInput } from '../Form/Input';
import { Col, Row } from 'react-bootstrap';
import { ErrorBlocks } from '../Form/FormHook';
import { COUNTRIES } from './models/countries.constant';
import { nameBySuffix } from '../../login/helpers/registration';

type Props = {
  showErrorsBlock: ErrorBlocks;
  prefix?: string;
};
const keys = ['address', 'address_number', 'ZIP', 'city', 'country'];

export const Address: React.FC<Props> = React.memo(
  ({ showErrorsBlock, prefix }) => {
    const nameWithPrefix: Record<string, string> = keys.reduce((acc, key) => ({
      [key]: prefix? `${prefix}_${key}`: key,
    }), {})

    return (
      <div className="company-address">
        <Row>
          <Col>
            <CustomInput
              name={nameWithPrefix['address']}
              placeholder="Address"
              required
              error={showErrorsBlock[nameWithPrefix['address']]}
            />
          </Col>
          <Col>
            <CustomInput
              name={nameWithPrefix['address_number']}
              placeholder="Address Number"
              required
              error={showErrorsBlock[nameWithPrefix['address_number']]}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <CustomInput
              name={nameWithPrefix['ZIP']}
              placeholder="ZIP"
              required
              error={showErrorsBlock[nameWithPrefix['ZIP']]}
            />
          </Col>
          <Col md={3}>
            <CustomInput
              name={nameWithPrefix['city']}
              placeholder="City"
              required
              error={showErrorsBlock[nameWithPrefix['city']]}
            />
          </Col>
          <Col md={6}>
            <CustomSelect
              name={nameWithPrefix['country']}
              placeholder="Country"
              options={COUNTRIES}
              required
              error={showErrorsBlock[nameWithPrefix['country']]}
            />
          </Col>
        </Row>
      </div>
    );
  },
);
