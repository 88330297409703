import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { selectUser } from '../../store/reducers/user/userReducer';
import { CompanyInfo } from '../components/Profiles/CompanyInfo/CompanyInfo';

export const CompanyInfoProfile: React.FC = () => {
  const user = useAppSelector(selectUser);

  return <CompanyInfo company={user!.company} isAdmin={false} />;
};
