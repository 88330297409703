import React, { useState } from 'react';
import { UserForm } from '../login/UserForm';
import { useAppDispatch } from '../../store/hooks';
import { fetchRegistration } from '../../API/login';
import { getRegistrationDataFromFormData } from '../login/helpers/registration';
import { CustomInput } from '../components/Form/Input';
import { BaseButtonSubmit } from '../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';
import { CheckBox } from '../components/Form/CheckBox/CheckBox';
import { CustomSelect, Option } from '../components/CustomSelect/CustomSelect';
import { Col, Row } from 'react-bootstrap';
import { CompanyType } from '../../structure/models/company/company';
import { useFormActions } from '../components/Form/FormHook';
import { validate } from './helpers/registration';
import { Address } from '../components/Address/Address';

import './RegistrationStyle.scss';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { STATEMENTS } from '../../structure/routes/routes';
import { DatePicker } from '../components/Form/DatePicker';

const conditions = 'I accept the General Terms & Conditions and Privacy Policy';
const required = true;

const options: {
  value: CompanyType;
  label: string;
}[] = [
  { value: CompanyType.Supplier, label: 'Product Supplier' },
  { value: CompanyType.Airline, label: 'Transport service provider' },
  { value: CompanyType.Forwarder, label: 'Forwarder' },
  { value: CompanyType.Aggregator, label: 'Aggregator' },
  { value: CompanyType.Corporate, label: 'Corporate' },
];

export const Registration: React.FC = React.memo(() => {
  const dispatch = useAppDispatch();
  const [userType, setUserType] = useState<CompanyType>();
  const navigate = useNavigate();

  const transformAndDispatchData = (formData: FormData) => {
    const registrationData = getRegistrationDataFromFormData(formData);
    return dispatch(fetchRegistration(registrationData)).then(() => {
      toast(
        "Success: You will get email with password after administrator's approve.",
        {
          type: 'success',
        },
      );

      navigate(STATEMENTS);
    });
  };

  const { onSubmit, showErrorsBlock, loading } = useFormActions({
    validate,
    transformAndDispatchData,
  });

  const onChangeType = (option: Option | null) => {
    const value = option?.value;
    setUserType(value);
  };

  const rsb = userType === CompanyType.Supplier && (
    <>
      <Col md={6}>
        <CustomInput
          placeholder="RSB PO number"
          name="rsb"
          type="text"
          required={required}
          error={showErrorsBlock['rsb']}
        />
      </Col>
      <Col md={6}>
        <DatePicker
          placeholder="Certificate validity start date"
          name="start_date"
          required={required}
          error={showErrorsBlock['start_date']}
        />
      </Col>
      <Col md={6}>
        <DatePicker
          placeholder="Certificate validity end date"
          name="end_date"
          required={required}
          error={showErrorsBlock['end_date']}
        />
      </Col>
    </>
  );

  return (
    <div>
      <div className="registration">
        <div className="registration__welcome">
          <h2>Welcome!</h2>
          <p className="under_h">
            Please sign in for access to our client and member sites
          </p>
        </div>

        <div className="registration__form_data">
          <form onSubmit={onSubmit}>
            <div className="title">General Data</div>
            <div className="form_block">
              <CustomInput
                placeholder="Company name"
                name="company_name"
                type="text"
                required={required}
                error={showErrorsBlock['company_name']}
              />
              <Row>
                <Col>
                  <CustomSelect
                    onChange={onChangeType}
                    name="register_role"
                    placeholder="Type"
                    required
                    options={options}
                    error={showErrorsBlock['register_role']}
                  />
                </Col>
                {rsb}
              </Row>
              <Address showErrorsBlock={showErrorsBlock} />
            </div>

            <div className="title">Primary contact</div>

            <div className="form_block">
              <UserForm
                suffix="primary"
                showErrorBlocks={showErrorsBlock}
                required={required}
              />
            </div>

            <div className="title">Secondary contact</div>
            <div className="form_block">
              <UserForm
                suffix="secondary"
                showErrorBlocks={showErrorsBlock}
                required={required}
              />
            </div>

            <div className="form_block">
              <CheckBox name="agreement" error={showErrorsBlock['agreement']}>
                {conditions}
              </CheckBox>
            </div>

            <BaseButtonSubmit active loading={loading} lock={loading}>
              Submit
            </BaseButtonSubmit>
          </form>
        </div>
      </div>
    </div>
  );
});
