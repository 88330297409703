import { actions } from './fetchProvider/fetch';
import { AppThunk } from '../store/store';
import { setUserData } from '../store/reducers/user/userReducer';
import { User } from '../structure/models/user/user';
import { URL_GET_USER } from './urls/urls';
import { AxiosResponse } from 'axios';
import { ServerResponse } from './types';

export const fetchUserData = (): AppThunk<Promise<void>> => dispatch => {
  return actions
    .get<AxiosResponse<ServerResponse<User>>>(URL_GET_USER)
    .then((userData: AxiosResponse<ServerResponse<User>>) => {
      dispatch(setUserData(userData.data.data));
    });
};
