import { TransferData } from '../../../structure/request/transfer';

export const getTransferDataFromFormData = (
  formData: FormData,
): TransferData => {
  const bcuid = formData.get('bcuid')?.toString() ?? '';
  const buyer_id = formData.get('buyer')?.toString() ?? '-1';
  const date = formData.get('date')?.toString() ?? new Date().toISOString();
  const location = formData.get('location')?.toString() ?? '';
  const volume = formData.get('volume')?.toString() ?? '-1';

  return {
    bcuid,
    buyer_id: +buyer_id,
    date,
    location,
    volume: +volume,
  };
};
