import { Company, CompanyType } from '../structure/models/company/company';
import { DateTime } from 'luxon';

export const FAKE_COMPANY: Company = {
  id: '1',
  name: 'FAKE COMPANY',
  address: 'address',
  addressNumber: '123',
  city: '123',
  country: '123123',
  zip: '2312323',
  type: CompanyType.Supplier,
  RSBPONumber: '123123123',
  certificateValidityStartDate: new Date().toString(),
  certificateValidityEndDate: new Date().toString(),
};

export const GenerateFakeCompany = (
  index: number,
  type = CompanyType.Supplier,
) => {
  return {
    id: `${index + 1}`,
    name: `FAKE COMPANY ${index}`,
    address: `address ${index}`,
    addressNumber: `address number ${index}`,
    city: `city ${index}`,
    country: `country ${index}`,
    zip: `zip ${index}`,
    type,
    RSBPONumber: `RSB00${index}`,
    certificateValidityStartDate: DateTime.now().toISODate(),
    certificateValidityEndDate: DateTime.now().toISODate(),
  };
};
