import React from 'react';
import classNames from 'classnames';
export type SortOrderD = 'asc' | 'desc';

export type SortOrder = {
  sortBy: string;
  sortOrder: SortOrderD;
};

type Props = {
  sort: SortOrder;
  keyItem: string;
  title: string;
  onChangeSort: (k: string) => void;
};
export const SortItem: React.FC<Props> = React.memo(
  ({ sort, onChangeSort, keyItem, title }) => {
    const active = sort.sortBy === keyItem;
    const sortIcon =
      sort.sortOrder === 'asc' || !active ? 'arrow_upward' : 'arrow_downward';

    const onClick = () => {
      onChangeSort(keyItem);
    };

    return (
      <div
        className={classNames('sort', {
          active,
        })}
        onClick={onClick}
      >
        <div className="sort_icon">
          <span className="material-symbols-outlined">{sortIcon}</span>
        </div>
        <div className="text">{title}</div>
      </div>
    );
  },
);
