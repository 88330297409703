import { validationFactory } from '../../core/helpers/validationFactory';
import {
  SetPasswordData,
  ChangePasswordData,
} from '../../../structure/request/change-password';

const { createValidator, createValidate } = validationFactory();

export const getFormChangePasswordData = (
  formData: FormData,
  token?: string | null,
): ChangePasswordData | SetPasswordData => ({
  password: formData.get('password')?.toString() || '',
  ...(token ? { token } : {}),
});

const validators = {
  password: createValidator(
    (value: string) =>
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(value),
    'Password must contain 8 character, special sign, number and capital letter.',
  ),
};

export const validate = createValidate(validators);
