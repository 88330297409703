import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ViewElement } from '../../ViewElement/ViewElement';
import { Company } from '../../../../structure/models/company/company';
import { SubmissionsCompany } from '../../../../structure/models/admin/submissions';

import './UserInfoStyle.scss';

interface Props {
  users: SubmissionsCompany['users'];
  company: Company;
}

export const UserInfo: React.FC<Props> = ({ users, company }) => {
  const [primaryUser, secondaryUser] = users;

  // TODO change this shit code
  return (
    <div className="user-info">
      <Row className="user-info__header-block">
        {secondaryUser ? (
          <>
            <Col md="5">Primary Contact</Col>
            <Col md="5">Secondary Contact</Col>
          </>
        ) : (
          <>
            <h1>
              {primaryUser.firstname} {primaryUser.lastname}
            </h1>
            <ViewElement label={`ID: ${primaryUser.SUID}`} dots={false} />
          </>
        )}
      </Row>
      <Row>
        <Col md="5" className="user-info__info-block">
          {secondaryUser && (
            <ViewElement
              label="Name"
              description={`${primaryUser.firstname} ${primaryUser.lastname}`}
            />
          )}
          <ViewElement label="Position" description={primaryUser.position} />
          <ViewElement
            label="Address"
            description={`${company.address} ${company.addressNumber} ${company.city}, ${company.country} ${company.zip}`}
          />
          <ViewElement label="Cell Phone" description={primaryUser.cellPhone} />
        </Col>
        {secondaryUser && (
          <Col md="5" className="user-info__info-block">
            <ViewElement
              label="Name"
              description={`${secondaryUser.firstname} ${secondaryUser.lastname}`}
            />
            <ViewElement
              label="Position"
              description={secondaryUser.position}
            />
            <ViewElement
              label="Address"
              description={`${company.address} ${company.addressNumber} ${company.city}, ${company.country} ${company.zip}`}
            />
            <ViewElement
              label="Cell Phone"
              description={secondaryUser.cellPhone}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};
