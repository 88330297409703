import React from 'react';
import { CompanyInfo } from '../../../components/Profiles/CompanyInfo/CompanyInfo';
import { useAppSelector } from '../../../../store/hooks';
import { selectSubmissionsCompany } from '../../../../store/reducers/admin/submitssionsReducer';

export const AdminCompanyInfo: React.FC = () => {
  const company = useAppSelector(selectSubmissionsCompany);

  return <CompanyInfo company={company || {}} isAdmin />;
};
