import { AppThunk } from '../store/store';
import { actions } from './fetchProvider/fetch';
import { TransferData } from '../structure/request/transfer';
import { URL_TRANSFER } from './urls/urls';

export const fetchTransfer =
  (transferData: TransferData): AppThunk<Promise<void>> =>
  dispatch => {
    return actions.post(URL_TRANSFER, {
      payloads: transferData,
    });
  };
