import React from 'react';
import { CompanyInfo } from '../../../components/Profiles/CompanyInfo/CompanyInfo';
import { useAppSelector } from '../../../../store/hooks';
import { selectSubmissionsCompany } from '../../../../store/reducers/admin/submitssionsReducer';
import { UserInfo } from '../../../components/Profiles/UserInfo/UserInfo';

export const AdminUsersInfo: React.FC = () => {
  const company = useAppSelector(selectSubmissionsCompany);

  return <UserInfo users={company.users} company={company} />;
};
