import React, { useState } from 'react';
import { UserBalance } from '../../components/UserBalance/UserBalance';
import { Alert, Col, Row } from 'react-bootstrap';
import { CustomInput } from '../../components/Form/Input';
import { BaseButtonSubmit } from '../../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { FormKeys } from '../../../structure/request/transfer';
import { getTransferDataFromFormData } from '../../transfer/helpers/transfer';
import { fetchTransfer } from '../../../API/transfer';
import { useAppDispatch } from '../../../store/hooks';
import { FormError } from '../../core/models/interfaces/formError';

export const Retirement: React.FC = React.memo(() => {
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<FormError[]>([]);

  const sendForm = (formData: FormData) => {
    const transferData = getTransferDataFromFormData(formData);
    dispatch(fetchTransfer(transferData))
      .then(() => {
        setErrors([]);
      })
      .catch(errors => {
        toast.error(`Error: ${errors}`);
        // TODO: Create errors
        // setErrors(errors);
      });
  };

  const validate = (
    entries: IterableIterator<[string, FormDataEntryValue]>,
  ): (true | FormError)[] => {
    const arr = Array.from(entries) as FormKeys;
    return arr.map(([key, value]) => {
      switch (key) {
        default:
          return true;
      }
    });
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const validateData = validate(formData.entries());
    if (validateData.every(d => d === true)) {
      sendForm(formData);
    } else {
      const errors = validateData.map(v => {
        if (v !== true) {
          return <div key={v.key}>{v.description}</div>;
        }
        return null;
      });
      toast.error(<>{errors}</>);
    }
  };

  const errs = errors.map(error => (
    <Alert key={error.code} variant={'danger'}>
      {error.description}
    </Alert>
  ));

  const showErrors = errors.length > 0 ? errs : null;
  const lockSubmit = false;

  return (
    <div>
      <UserBalance />
      <div className="transfer_container">
        <form onSubmit={onSubmit}>
          <div>
            <h1>BCU Retirement</h1>
            <Row>
              <Col></Col>
              <Col>
                <CustomInput
                  placeholder={'Volume of credit transferred'}
                  name={'volume'}
                  type={'number'}
                  required={true}
                />
              </Col>
            </Row>
            <Row>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <CustomInput
                  placeholder={'Location where SAF is uplifted'}
                  name={'location'}
                  type={'text'}
                  required={true}
                />
              </Col>
              <Col>
                <CustomInput
                  placeholder={'Date when SAF is uplifted'}
                  name={'date'}
                  type={'text'}
                  required={true}
                />
              </Col>
            </Row>
          </div>
          <div className="actions">
            <BaseButtonSubmit
              lock={lockSubmit}
              className={classNames({
                base_color: !lockSubmit,
              })}
            >
              Submit
            </BaseButtonSubmit>
          </div>
        </form>

        <div className="errors">{showErrors}</div>
      </div>
    </div>
  );
});
