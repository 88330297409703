import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';

import './TabElementStyle.scss';

type Props = {
  link: string;
  replace: boolean;
  childRoutes?: string[];
};

export const TabElement: React.FC<React.PropsWithChildren<Props>> = React.memo(
  ({ children, link, replace, childRoutes: rawChildRoutes }) => {
    const location = useLocation();
    const { id } = useParams();
    const childRoutes = rawChildRoutes?.map(route =>
      id ? route.replace(':id', id) : route,
    );

    return (
      <Link
        to={link}
        className={classNames('tab_element', {
          active:
            location.pathname === link ||
            childRoutes?.includes(location.pathname),
        })}
        replace={replace}
      >
        {children}
      </Link>
    );
  },
);
