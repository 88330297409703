import { AppThunk } from '../store/store';
import { actions } from './fetchProvider/fetch';
import { AxiosResponse } from 'axios';
import { ServerResponse } from './types';
import { URL_GENERATE_TOKEN } from './urls/urls';

export type TokenResponse = {
  accessToken: string;
};

interface GenerateTokenResponse {
  token: string;
}

export const fetchGenerateToken = (): AppThunk<Promise<string>> => () => {
  return actions
    .post<AxiosResponse<ServerResponse<GenerateTokenResponse>>>(
      URL_GENERATE_TOKEN,
    )
    .then((response: AxiosResponse<ServerResponse<GenerateTokenResponse>>) => {
      return response.data.data.token;
    });
};
