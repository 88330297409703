import React, { useEffect, useState } from 'react';
import { PageWithTabsLayout } from '../../../layouts/PageWithTabsLayout';
import { COMPANY_TABS } from './models/company-tabs.constant';
import { useNavigate, useParams } from 'react-router-dom';
import {
  fetchRegistrationApprove,
  fetchRegistrationReject,
  fetchSubmissionsCompanyInfo,
} from '../../../../API/admin';
import { useAppDispatch } from '../../../../store/hooks';

import './AdminCompanyInfoLayoutStyle.scss';
import { BaseButtonSubmit } from '../../../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';
import { RejectModal } from './RejectModal';
import { toast } from 'react-toastify';

export const AdminCompanyInfoLayout: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const goBack = () => navigate(-1);

  const onRejectResult = (status: 'success' | 'error') => {
    if (status === 'error') {
      return;
    }
    setShowRejectModal(false);
    toast.success('Success: Rejected');
    goBack();
  };

  const onReject = () => setShowRejectModal(true);
  const onApprove = () => {
    setLoading(true);
    dispatch(fetchRegistrationApprove({ id }))
      .then(() => {
        toast.success('Success: Registration approved');
        goBack();
      })
      .catch(e => {
        toast.error(`Error: ${e.response.data.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onHide = () => {
    setShowRejectModal(false);
  };

  useEffect(() => {
    dispatch(fetchSubmissionsCompanyInfo(id!));
  }, []);

  const back = (
    <div onClick={goBack} className="back">
      <span className="material-symbols-outlined">arrow_back_ios</span>
      Registration Request
    </div>
  );

  const actionButtons = (
    <div className="action-buttons">
      <BaseButtonSubmit onClick={onReject}>Reject</BaseButtonSubmit>
      <BaseButtonSubmit active loading={loading} onClick={onApprove}>
        Approve
      </BaseButtonSubmit>
    </div>
  );

  return (
    <>
      <PageWithTabsLayout
        aboveWrapper={back}
        underWrapper={actionButtons}
        tabs={COMPANY_TABS.map(tab => ({
          ...tab,
          path: tab.path.replace(':id', id as string),
        }))}
      />
      <RejectModal
        id={id!}
        fetchData={fetchRegistrationReject}
        onFetchResult={onRejectResult}
        centered
        show={showRejectModal}
        onHide={onHide}
      />
    </>
  );
};
