import React from 'react';
import { PaginatedTable } from '../../../components/Table/Table';
import { fetchSubmissionsUsers } from '../../../../API/admin';
import {
  SubmissionsStatus,
  SubmissionsStatusBE,
  SubmissionsType,
} from '../../../../structure/request/admin';
import {
  selectSubmissionsStatus,
  selectSubmissionsUsers,
} from '../../../../store/reducers/admin/submitssionsReducer';
import { SubmissionsUsers } from '../../../../structure/models/admin/submissions';
import { Col } from 'react-bootstrap';
import {
  CustomSelect,
  Option,
} from '../../../components/CustomSelect/CustomSelect';
import { REGISTRATION_COLUMNS } from './models/columns.constant';
import { useSearchParamsState } from '../../../components/Table/Pagination/PaginationHook';
import { SingleValue } from 'react-select';

import './SubmissionsListStyle.scss';
import { useNavigate } from 'react-router-dom';
import { ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES } from '../../../../structure/routes/routes';

const rowKey = (item: SubmissionsUsers) => item.target.SUID;

const type: string = SubmissionsType.Registration;

const TABLE_PROPS = {
  [SubmissionsType.BCURegistration]: {
    fetchData: fetchSubmissionsUsers,
    selectData: selectSubmissionsUsers,
  },
};

const types: Option[] = [
  { value: SubmissionsType.Registration, label: SubmissionsType.Registration },
  {
    value: SubmissionsType.BCURetirement,
    label: SubmissionsType.BCURetirement,
  },
  {
    value: SubmissionsType.BCURegistration,
    label: SubmissionsType.BCURegistration,
  },
  { value: SubmissionsType.BCUTransfer, label: SubmissionsType.BCUTransfer },
];

const statuses: Option[] = [
  { value: null, label: 'All' },
  { value: SubmissionsStatusBE.Approved, label: SubmissionsStatus.Approved },
  { value: SubmissionsStatusBE.Declined, label: SubmissionsStatus.Rejected },
  {
    value: SubmissionsStatusBE.Pending,
    label: SubmissionsStatus.Consideration,
  },
];

const disabledOptions = [
  SubmissionsType.BCUTransfer,
  SubmissionsType.BCURetirement,
  SubmissionsType.BCURegistration,
];

export const SubmissionList: React.FC = () => {
  const navigate = useNavigate();
  const [type, setType] = useSearchParamsState<SubmissionsType>(
    'type',
    SubmissionsType.Registration,
  );
  const [status, setStatus] = useSearchParamsState('status', null);

  const onChangeStatus = (option: SingleValue<Pick<Option, 'value'>>) => {
    setStatus(option?.value);
  };

  const onRowClick = (item: SubmissionsUsers) => {
    navigate(
      ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.ADMIN_SUBMISSIONS_LIST_COMPANY_INFO.replace(
        ':id',
        item.id,
      ),
    );
  };

  return (
    <div className="submissions-list">
      <div className="submissions-list__header">
        <h1>Submissions list</h1>
        <div className="submissions-list__header__controls">
          <Col>
            <CustomSelect
              placeholder="Type"
              options={types}
              isOptionDisabled={option =>
                disabledOptions.includes(option.value)
              }
              value={types[0]}
            />
          </Col>
          <Col>
            <CustomSelect
              placeholder="Status"
              options={statuses}
              onChange={onChangeStatus}
              value={statuses.find(value => value.value === status)}
            />
          </Col>
        </div>
      </div>
      <PaginatedTable
        fetchData={fetchSubmissionsUsers}
        selectData={selectSubmissionsUsers}
        selectStatus={selectSubmissionsStatus}
        filters={{
          type,
          status,
        }}
        columns={REGISTRATION_COLUMNS}
        rowKey={rowKey}
        onRowClick={onRowClick}
      />
    </div>
  );
};
