import React from 'react';
import { Modal, ModalProps } from '../../../components/Modal/Modal';
import { BaseButtonSubmit } from '../../../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';
import { useFormActions } from '../../../components/Form/FormHook';
import { useAppDispatch } from '../../../../store/hooks';

import './RejectModalStyle.scss';

interface Props extends Omit<ModalProps, 'header'> {
  id: string;
  fetchData: any;
  onFetchResult: (status: 'success' | 'error', error?: Error) => void;
}

const header = (
  <div className="reject-modal__header">
    <span className="material-symbols-outlined">error_outline</span>Reason for
    Rejection
  </div>
);

export const RejectModal: React.FC<Props> = ({
  id,
  fetchData,
  onFetchResult,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const transformAndDispatchData = (formData: FormData) => {
    const message = formData.get('message')?.toString() || '';

    return dispatch(fetchData({ id, message })).then(() => {
      onFetchResult('success');
    });
  };

  const { onSubmit, loading } = useFormActions({
    validate: () => [true],
    transformAndDispatchData,
  });

  return (
    <Modal {...props} header={header} className="reject-modal">
      <form onSubmit={onSubmit} className="reject-modal__form">
        <textarea name="message" cols={48} rows={10}></textarea>
        <div className="action-buttons">
          <BaseButtonSubmit active loading={loading}>
            Confirm and Send
          </BaseButtonSubmit>
        </div>
      </form>
    </Modal>
  );
};
