import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userReducer from './reducers/user/userReducer';
import adminSubmissionsReducer from './reducers/admin/submitssionsReducer';
import { ThunkDispatch } from 'redux-thunk/src/types';

export const store = configureStore({
  reducer: {
    user: userReducer,
    adminSubmissions: adminSubmissionsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppThunkDispatch = ThunkDispatch<
  RootState,
  unknown,
  Action<string>
>;
