import React from 'react';
import { UserBalance } from '../../components/UserBalance/UserBalance';
import { PaginationComponent } from '../../components/PaginationComponent/PaginationComponent';
import { usePaginator } from '../../components/Table/Pagination/PaginationHook';
import { TransactionsItemList } from '../TransactionsItemList/TransactionsItemList';
import './TransactionsStyle.scss';
import { URL_GET_CERTIFICATES } from '../../../API/urls/urls';

export const Transactions: React.FC = React.memo(() => {
  // const { data, paginationNavigation, showBy } = usePaginator([], [], '');

  return (
    <div>
      <UserBalance />
      <div className="transactions_container">
        {/*<PaginationComponent data={data}>*/}
        {/*  /!* TODO: мб переписать чтобы не чилдом потому что типы чет геморно настраивать и не понятно как прописать что я типы внутри закину *!/*/}
        {/*  /!* @ts-ignore} *!/*/}
        {/*  <TransactionsItemList />*/}
        {/*</PaginationComponent>*/}
        {/*<div className="transactions_navigation">*/}
        {/*  {paginationNavigation}*/}
        {/*  {showBy}*/}
        {/*</div>*/}
      </div>
    </div>
  );
});
