import React from 'react';
import { CustomInput, CustomInputProps } from './Input';

interface Props extends Omit<CustomInputProps, 'type' | 'someAfter'> {}

export const DatePicker: React.FC<Props> = ({ onBlur, onFocus, ...props }) => {
  const datePickerOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.type = 'text';
    onBlur?.(event);
  };

  const datePickerOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.type = 'date';
    onFocus?.(event);
  };

  return (
    <CustomInput
      placeholder="Certificate validity start date"
      name="start_date"
      type="text"
      onFocus={datePickerOnFocus}
      onBlur={datePickerOnBlur}
      someAfter={
        <span className="material-symbols-outlined">calendar_month</span>
      }
      {...props}
    />
  );
};
