import { Company } from '../company/company';

export enum ProductType {
  SAF = 'SAF',
}

export interface Certificate {
  id: number;
  bcuid: string;
  volume: number;
  type: ProductType;
}

export interface CertificateOption {
  readonly value: number;
  readonly label: string;
}

export interface Retirement {
  rsid: string;
  certificate: Certificate;
  date: string;
  seller: Company;
  buyer: Company;
  link: string;
  volume: number;
}
