import {
  DASHBOARD,
  DASHBOARD_ROUTES,
  LOGIN,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  REGISTRATION,
  STATEMENTS,
  PROFILE_ROUTES,
  ADMIN_CREDITS,
  ADMIN_LOGS,
  ADMIN_USERS_AND_TRANSACTIONS,
  ADMIN_SUBMISSIONS_LIST,
  ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES,
} from '../../../../structure/routes/routes';
import { Role } from '../../../../structure/models/user/user';

const createAccess = (
  roles: Array<Role | undefined>,
  redirect: string = STATEMENTS,
) => ({ roles, redirect });

const userRolesAccess = createAccess([Role.SystemUser, Role.Auditor]);
const adminRolesAccess = createAccess([Role.Admin, Role.SuperAdmin]);
const allRolesAccess = createAccess([
  undefined,
  Role.Admin,
  Role.SuperAdmin,
  Role.SystemUser,
  Role.Auditor,
]);
const unauthorizedAccess = createAccess([undefined]);

export const pageAccesses: Record<
  string,
  { roles: Array<Role | undefined>; redirect: string }
> = {
  [DASHBOARD]: userRolesAccess,
  [DASHBOARD_ROUTES.LOGS]: userRolesAccess,
  [DASHBOARD_ROUTES.TRANSFER]: userRolesAccess,
  [DASHBOARD_ROUTES.REGISTER_CREDIT]: userRolesAccess,
  [DASHBOARD_ROUTES.RETIREMENT]: userRolesAccess,
  [PROFILE_ROUTES.USER_PROFILE]: userRolesAccess,
  [PROFILE_ROUTES.COMPANY_PROFILE]: userRolesAccess,
  [ADMIN_CREDITS]: adminRolesAccess,
  [ADMIN_LOGS]: adminRolesAccess,
  [ADMIN_USERS_AND_TRANSACTIONS]: adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST]: adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.ADMIN_SUBMISSIONS_LIST_COMPANY_INFO]:
    adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.ADMIN_SUBMISSIONS_LIST_USERS_INFO]:
    adminRolesAccess,
  [CHANGE_PASSWORD]: allRolesAccess,
  [REGISTRATION]: unauthorizedAccess,
  [FORGOT_PASSWORD]: unauthorizedAccess,
  [LOGIN]: unauthorizedAccess,
  [STATEMENTS]: allRolesAccess,
};
