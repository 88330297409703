import { AppThunk } from '../store/store';
import { Company } from '../structure/models/company/company';
import { FAKE_COMPANY } from '../FAKE_DATA/conpany';
import { Certificate } from '../structure/models/certificates/certificates';
import { generateFakeCertificates } from '../FAKE_DATA/certificate';

export const fetchGetCompanyByToken =
  (token: string): AppThunk<Promise<Company>> =>
  dispatch => {
    return Promise.resolve(FAKE_COMPANY);
    // return actions.get<Company>(URL_CHECK_CODE, {
    //   queries: {
    //     token,
    //   },
    // });
  };

export const fetchGetCompaniesByUser =
  (): AppThunk<Promise<Company[]>> => (dispatch, getState) => {
    return Promise.resolve([FAKE_COMPANY, FAKE_COMPANY, FAKE_COMPANY]);

    // const user = getState().user.userData?.id;
    // if (user) {
    //   return actions.get<Company[]>(URL_CHECK_CODE, {
    //     queries: {
    //       user,
    //     },
    //   });
    // }
    // return Promise.resolve([]);
  };

export const fetchGetCompaniesCertificates =
  (): AppThunk<Promise<Certificate[]>> => (dispatch, getState) => {
    return Promise.resolve(generateFakeCertificates(5));

    // return actions.get<Certificate[]>(URL_GET_CERTIFICATES);
  };
