import {
  CheckCodeData,
  LoginCredit,
  LoginOtpResponse,
  LoginTokenResponse,
} from '../structure/request/login';
import { actions } from './fetchProvider/fetch';
import { AppThunk } from '../store/store';
import { changeToken, logout } from '../store/reducers/user/userReducer';
import { RegistrationData } from '../structure/request/registration';
import {
  URL_CHECK_CODE,
  URL_LOGIN,
  URL_LOGOUT,
  URL_FORGOT_PASSWORD,
  URL_REFRESH_TOKEN,
  URL_REGISTRATION,
  URL_SET_PASSWORD,
  URL_CHANGE_PASSWORD,
  URL_CONFIRM_EMAIL,
} from './urls/urls';
import { AxiosPromise, AxiosResponse } from 'axios';
import {
  ChangePasswordData,
  SetPasswordData,
} from '../structure/request/change-password';
import { ServerResponse } from './types';
import { TokenResponse } from './token';

export const refreshToken = (): AppThunk<Promise<void>> => dispatch => {
  return actions
    .get<AxiosResponse<ServerResponse<TokenResponse>>>(URL_REFRESH_TOKEN)
    .then(token => {
      dispatch(changeToken(token.data.data.accessToken));
    });
};

export const fetchRegistration =
  (registrationData: RegistrationData): AppThunk<Promise<void>> =>
  dispatch => {
    return actions
      .post<string>(URL_REGISTRATION, {
        payloads: registrationData,
      })
      .then(token => {
        dispatch(changeToken(token));
      });
  };

export const fetchSetPassword =
  (payloads: SetPasswordData): AppThunk<Promise<void>> =>
  () => {
    return actions.post<void>(URL_SET_PASSWORD, {
      payloads,
    });
  };

export const fetchChangePassword =
  (payloads: ChangePasswordData): AppThunk<Promise<void>> =>
  () => {
    return actions.post<void>(URL_CHANGE_PASSWORD, {
      payloads,
    });
  };

export const fetchForgotPassword =
  (email: string): AppThunk<Promise<void>> =>
  () => {
    return actions.post<void>(URL_FORGOT_PASSWORD, {
      payloads: {
        email,
      },
    });
  };

export const fetchLogin =
  (
    loginCredit: LoginCredit,
  ): AppThunk<AxiosPromise<LoginTokenResponse | LoginOtpResponse>> =>
  dispatch => {
    return actions
      .post<AxiosResponse<LoginTokenResponse | LoginOtpResponse>>(URL_LOGIN, {
        payloads: loginCredit,
      })
      .then((resp: AxiosResponse<LoginTokenResponse | LoginOtpResponse>) => {
        // after entered correct OTP
        if (typeof resp.data.data !== 'string') {
          dispatch(changeToken(resp.data.data.accessToken));
        }

        return resp;
      });
  };

export const fetchLogOut = (): AppThunk<Promise<unknown>> => dispatch => {
  return actions.post(URL_LOGOUT).finally(() => {
    dispatch(logout());
  });
};

export const fetchConfirmEmail =
  (token: string): AppThunk<AxiosPromise<ServerResponse<string>>> =>
  () => {
    return actions.post<AxiosResponse<ServerResponse<string>>>(
      URL_CONFIRM_EMAIL,
      {
        payloads: { token },
      },
    );
  };
