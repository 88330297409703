import { validationFactory } from '../../core/helpers/validationFactory';

const { createValidator, createValidate } = validationFactory();

export const getFormForgotPasswordData = (formData: FormData): string =>
  formData.get('email')?.toString() || '';

const validators = {
  email: createValidator(
    (value: string) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value),
    'Email must have correct format.',
  ),
};

export const validate = createValidate(validators);
