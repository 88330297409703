import React from 'react';
import { Col, Row } from 'react-bootstrap';

import './ViewElementStyle.scss';

interface Props {
  label?: React.ReactNode;
  description?: React.ReactNode;
  dots?: boolean;
}

export const ViewElement: React.FC<Props> = ({
  label,
  description,
  dots = true,
}) => {
  return (
    <Row className="view-element">
      <Col className="view-element__label">
        {label}
        {dots ? ':' : ''}
      </Col>
      <Col className="view-element__description">{description}</Col>
    </Row>
  );
};
